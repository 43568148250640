import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export default class AllRentalsRental extends Component {
  renderStatusImage() {
    if (this.props.status === 'succeeded') {
      return <i className="fa fa-check all-rentals-element__icon" />;
    } else if (this.props.status === 'attempted') {
      return <i className="fa fa-ban all-rentals-element__icon" />;
    } else if (this.props.status === 'canceled') {
      return <i className="fa fa-trash all-rentals-element__icon" />;
    } else if (this.props.status === 'mock') {
      return <i className="fa fa-circle-o all-rentals-element__icon" />;
    }
    return <i className="fa fa-circle-o all-rentals-element__icon" />;
  }

  renderReturnArrow() {
    if (this.props.rentalType === 'return') {
      return (
        <i
          className="fa fa-long-arrow-left all-rentals-element__icon
          all-rentals-element__icon--return-type"
        />
      );
    } else if (this.props.rentalType === 'pickup') {
      return (
        <i
          className="fa fa-long-arrow-right all-rentals-element__icon
          all-rentals-element__icon--return-type"
        />
      );
    }
    return null;
  }

  renderUserIcon() {
    if (this.props.personIcon === 'person') {
      return <i className="fa fa-user all-rentals-element__icon" />;
    } else if (this.props.personIcon === 'person_outline') {
      return <i className="fa fa-user-circle-o all-rentals-element__icon" />;
    }
    return null;
  }

  // display fraud icon when this.props.fraudDetectedAt is true
  renderFraudIcon() {
    if (!this.props.fraudDetectedAt) { return };
    return (<i className="fa fa-exclamation-triangle all-rentals-element__icon" title="Fraud detected"/>);
  }

  render() {
    return (
      <div className="all-rentals-element__row">
        <div className="all-rentals-element__cell all-rentals-element__cell--time">
          <h3 className="all-rentals-element__main-data">
            {moment(this.props.createdAt).format('dddd').slice(0, 3)} {moment(this.props.createdAt).format('DD.MM.YY')}
          </h3>
          <h4 className="all-rentals-element__minor-data">{moment(this.props.createdAt).format('HH:mm')}</h4>
        </div>
        <div className="all-rentals-element__cell all-rentals-element__cell--arrow">{this.renderReturnArrow()}</div>
        <div className="all-rentals-element__cell all-rentals-element__cell--return">
          <h3 className="all-rentals-element__main-data all-rentals-element__main-data--no-wrap">{this.props.rentalTypeInfo}</h3>
          <h4 className="all-rentals-element__minor-data all-rentals-element__minor-data--no-wrap">{this.props.batteryId}</h4>
        </div>
        <div className="all-rentals-element__cell all-rentals-element__cell--user-icon">{this.renderUserIcon()}</div>
        <div className="all-rentals-element__cell all-rentals-element__cell--user-name">
          <h3 className="all-rentals-element__main-data all-rentals-element__main-data--no-wrap">{this.props.userName}</h3>
          <h4 className="all-rentals-element__minor-data all-rentals-element__minor-data--no-wrap">
            {this.props.totalAmountUser} {this.props.premiumAccount}
          </h4>
        </div>
        {/* fraud indicator */}
        <div className="all-rentals-element__cell all-rentals-element__cell--fraud">
          {this.renderFraudIcon()}
        </div>
        <div className="all-rentals-element__cell all-rentals-element__cell--location-icon">
          <i className="fa fa-map-marker all-rentals-element__icon" aria-hidden="true" />
        </div>
        <div className="all-rentals-element__cell all-rentals-element__cell--address">
          <h3 className="all-rentals-element__main-data all-rentals-element__main-data--no-wrap">{this.props.address.city}</h3>
          <h4 className="all-rentals-element__minor-data">{this.props.locationName}</h4>
          <h4 className="all-rentals-element__minor-data all-rentals-element__minor-data--region">
            {this.props.posNumber} - {this.props.address.region} {this.props.address.zip_code}
          </h4>
        </div>
        <div className="all-rentals-element__cell all-rentals-element__cell--status">{this.renderStatusImage()}</div>
      </div>
    );
  }
}

AllRentalsRental.propTypes = {
  status: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  rentalType: PropTypes.string.isRequired,
  batteryId: PropTypes.string.isRequired,
  totalAmountUser: PropTypes.string.isRequired,
  rentalTypeInfo: PropTypes.string.isRequired,
  personIcon: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  locationName: PropTypes.string.isRequired,
  posNumber: PropTypes.string.isRequired,
  address: PropTypes.object,
  premiumAccount: PropTypes.string,
  fraudDetectedAt: PropTypes.string,
};

AllRentalsRental.defaultProps = {
  premiumAccount: '',
  address: {},
  fraudDetectedAt: null,
};
