import React from 'react';
import PropTypes from 'prop-types';
import { BananaPaginator } from 'banana-framework';
import AllRentalsRental from './all-rentals-rental';

export default function AllRentalsList(props) {
  return (
    <div>
      <div className="all-rentals-element">
        {props.rentals.map((rental) => (
          <AllRentalsRental
            key={rental.id}
            batteryId={rental.battery_identifier}
            status={rental.status}
            createdAt={rental.created_at}
            rentalType={rental.rental_type}
            totalAmountUser={rental.total_amount_user || ''}
            premiumAccount={rental.premium_account}
            rentalTypeInfo={rental.rental_type_info}
            personIcon={rental.person_icon}
            userName={rental.user_name}
            locationName={rental.location_name}
            posNumber={rental.pos_number}
            address={rental.address ? rental.address : undefined}
            fraudDetectedAt={rental.fraud_detected_at}
          />
        ))}
      </div>
      {props.totalPages > 1 && (
        <BananaPaginator
          maxElements={10}
          activeComponent={props.page}
          elementAmount={props.totalPages}
          setActiveElement={props.setActiveElement}
        />
      )}
    </div>
  );
}

AllRentalsList.propTypes = {
  rentals: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  setActiveElement: PropTypes.func.isRequired,
};
